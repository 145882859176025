export const curriculum = {
  "type": "curriculum",
  "title": "Curriculum",
  "titleDesc": "CS Wonders courses are for students from 4th to 9th grade. You will learn computer " +
    "science through interactive tutorials and hands-on coding exercises.",
  "titleImage": "/img/rainbow.png",
  "items": [
    {
      "title": "Introduction to Programming 1",
      "color": "rgba(223, 11, 55, 1)",
      "link": "/python1",
      "desc": "This entry-level course is for students who have no computer programming experience. You will use Python to learn basic " +
        "programming concepts, including binary number systems, programming languages, variables, primary data types, " +
        "conditional statements, loops, and functions. You will design your own art work and solve interesting problems using Python. ",
      "image": "/img/clear_code-512.png"
    },
    {
      "title": "Introduction to Programming 2",
      "color": "rgba(247, 100, 56, 1)",
      "link": "/python2",
      "desc": "This course is for students who have successfully completed the Introduction to Programming 1 course or have " +
        "some equivalent experience. You will learn the computational thinking process and further explore more  " +
        "advanced programming topics, including collection data types, classes and objects, modules, scope, file IO, " +
        "and exception handling. You will implement some fun projects and solve interesting problems using Python. ",
      "image": "/img/clear_code-512.png"
    },
    {
      "title": "Introduction to Algorithms 1",
      "color": "rgba(111, 44, 162, 1)",
      "link": "/algorithms1",
      "desc": "This course is for students who have completed the Introduction to Programming courses or have some equivalent experience. " +
        "You will go beyond basic programming and learn how to write elegant code efficiently. " +
        "Study some classic computer science techniques and algorithms including divide and conquer, recursion, binary search, " +
        "insertion sort and selection sort. Algorithm analysis and Big-O notation are introduced. ", 
      "image": "/img/clear_code-512.png"
    },
    {
      "title": "Introduction to Algorithms 2",
      "color": "rgba(90, 69, 120, 1)",
      "link": "/algorithms2",
      "desc": "This course is for students who have completed the Introduction to Algorithms 1 course or have some equivalent experience. " +
        "You will be introduced to the concept of abstract data types (ADTs) and learn some basic ADTs including stacks, " +
        "queues, and hash tables. You will also study more efficient sorting algorithms such as merge sort and " +
        "quicksort. ",
      "image": "/img/clear_code-512.png"
    },
    {
      "title": "Introduction to Algorithms 3",
      "color": "rgba(37, 207, 33, 1)",
      "link": "/algorithms3",
      "desc": "This course is for students who have completed the Introduction to Algorithms 2 course or have some equivalent experience. " +
        "You will learn more data structures including arrays, linked lists, binary trees, and graphs. " +
        "You will also study more interesting algorithms such as breadth first search, depth first search, " +
        "greedy algorithm, and Dijkstra's algorithm. ",
      "image": "/img/clear_code-512.png"
    },
    {
      "title": "Game Programming with Turtle",
      "color": "rgba(255, 166, 0, 1)",
      "link": "/game-programming-with-turtle",
      "desc": "This course is for students who have successfully completed the Introduction to Programming courses or have some " +
        "equivalent experience. You will learn how to write online games using Turtle Graphics, and get familiar with " +
        "the concepts of computer graphics, animation, and event handling. ",
      "image": "/img/clear_code-512.png"
    },
    {
      "title": "Game Programming - Introduction to Pygame",
      "color": "rgba(255, 217, 0, 1)",
      "link": "/introduction-to-pygame",
      "desc": "This course is for students who have successfully completed the Introduction to Programming courses or have some " +
        "equivalent experience. You will use Pygame to learn the fundmentals of game programming including basic computer graphics, " +
        "animation, event handling, and object oriented design. ",
      "image": "/img/clear_code-512.png"
    },
    {
      "title": "Game Programming - Shooting Game",
      "color": "rgba(37, 207, 33, 1)",
      "link": "/shooting-game",
      "desc": "This course is for students who have successfully completed the Introduction to Pygame course. " +
        "You will learn to write a shmup game, in which a player character engages in a " +
        "lone assault, shooting large numbers of enemies while dodging their attacks.",
      "image": "/img/clear_code-512.png"
    },
    {
      "title": "Game Programming - Platform Game",
      "color": "rgba(12, 141, 255, 1)",
      "link": "/platform-game",
      "desc": "This course is for students who have successfully completed the Introduction to Pygame course. " +
        "You will learn to write a 2-dimentional platform game, in which a player character " +
        "or avatar jumps between suspended platforms and over obstacles to traverse the environment. ",
      "image": "/img/clear_code-512.png"
    },
    {
      "title": "ACSL Contest",
      "color": "rgba(37, 207, 33, 1)",
      "link": "/acsl",
      "desc": "This course helps students prepare for the acsl contest. ",
      "image": "/img/clear_code-512.png"
    },
 
    // {
    //   "title": "Level 5: Networking",
    //   "color": "rgba(37, 207, 33, 1)",
    //   "link": "/level5",
    //   "desc": "This course discusses some important computer science application areas including networking, data storage, " +
    //     "data compression, user interface, and image representation. ",
    //   "image": "/img/clear_code-512.png"
    // },
    // {
    //   "title": "Level 6: Internet Programming",
    //   "color": "rgba(12, 141, 255, 1)",
    //   "link": "/level6",
    //   "desc": "This course covers the fundmentals of the Internet - web browsers, client and server, concurrency programming, " +
    //     "database systems, security, search engines, and cloud computing.",
    //   "image": "/img/clear_code-512.png"
    // },
    // {
    //   "title": "Level 7: Scientific Computing",
    //   "color": "rgba(111, 44, 162, 1)",
    //   "link": "/level7",
    //   "desc": "What is scientific computing? In this course you will learn scientic modeling, simulation, big data, and " +
    //     "data visulization. ",
    //   "image": "/img/clear_code-512.png"
    // },
    // {
    //   "title": "Level 8: Artificial Intelligence",
    //   "color": "rgba(90, 69, 120, 1)",
    //   "link": "/level8",
    //   "desc": "Explore the exciting world of artificial intelligence " +
    //     "- machine learning, logic and planning, " +
    //     "robotics, computer vision, natural language processing, information retrieval, and computer gaming.",
    //   "image": "/img/clear_code-512.png"
    // },
  ]
};