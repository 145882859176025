/*
 * Home.js
 */
import useStyles from 'isomorphic-style-loader/useStyles';
import React from 'react';
import PropTypes from 'prop-types';
import s from './Home.scss';
import Catalog from '../../components/Catalog';
import UserSwitcher from '../../components/UserSwitcher';
import { curriculum } from '../../routes/curriculum/curriculum_catalog';
import ParentProfilePage from '../../components/ParentProfilePage';
import { isParent } from '../../util/util';

const splashSection = {
  "backgroundImage": "url(/img/splash.svg)",
  "height": "350px",
  "paddingTop": "62px",
  "backgroundPosition": "center",
  "backgroundSize": "cover",
};

const sloganHeader = {
  "margin": "auto",
  "paddingTop": "0px",
  "textAlign": "center",
};

const slogan = {
  "color": "#202020",
  "font": '400 34px "Roboto Slab"',
  "paddingRight": "0px",
  "margin": "0px",
  "textAlign": "center",
  "color": "rgba(24, 112, 187, 1)",
};

const subSlogan = {
  "fontSize": "16px",
  "fontWeight": "400",
  "color": "rgba(24, 112, 187, 1)",
};

const introTitle = {
  "backgroundColor": "#f6f8f8",
  "textAlign": "center",
  "color": "#666",
  "paddingTop": "20px",
  "fontSize": "200%",
  "fontWeight": "400",
};

const descriptionTitle = {
  "textAlign": "center",
  "paddingTop": "20px",
  "fontSize": "200%",
  "fontWeight": "400",
};

const intro = {
  "backgroundColor": "#f6f8f8",
  "textAlign": "center",
  "color": "#666",
  "fontSize": "120%",
  "fontWeight": "400",
  "padding": "10px",
};

const containerStyle = {
  "padding": "4px",
  "backgroundColor": "#efefef",
};

const descriptionContainerStyle = {
  // "backgroundColor": "rgba(2, 180, 241, 1)",
  "backgroundColor": "rgba(62, 170, 225, 1)",
  "color": "white",
  "textAlign": "center",
};

const descriptionStyle = {
  "padding": "20px",
  "borderRadius": "20px",
  "backgroundColor": "#f6f8f8",
  "color": "#666",
  "textAlign": "left",
  "fontSize": "15px",
};

const quoteStyle = {
  "fontStyle": "normal",
  "verticalAlign": "baseline",
  "fontSize": "22px",
  "fontWeight": "100",
  "fontFamily": "brandon-grot-w01-light,sans-serif",
  "lineHeight": "1.2em",
  "paddingTop": "10px",
  "paddingBottom": "20px",
};

const imgStyle = {
  "width": "100%",
};

const navHighlight = {
  base: {
    "padding": "0 1.25em",
    "display": "inline-block",
    "marginRight": "0px",
    "marginLeft": "5px",
    "borderRadius": "100px",
    "fontWeight": "500",
    "letterSpacing": "0.03125em",
    "fontSize": "90%",
    "lineHeight": "2.2",
    "border": "1px solid #ffae1c",
    "color": "#ffae1c",
    "backgroundColor": "white",

    ':hover': {
      "borderColor": "#ffae1c;",
      "backgroundColor": "#ffae1c",
      "color": "#fff",
      "textDecoration": "none"
    },

    ':focus': {
      "outline" :"0"
    },

    '@media screen and (max-width: 820px)': {
      "display": "none",
    },
  }
};

function getDescription() {
  return (
    <div>
        <div style={quoteStyle}>
          "Everybody should learn how to program a computer, because it teaches you how to think. I view computer science as a liberal art." – Steve Jobs
        </div>

        <p>
          Today, computer science (CS) matters more than ever.
          Our mission is to make computer science education effective and fun. We're doing this by providing 
          a personalized and adaptive education system that works both online and in classrooms.
        </p>
    </div>
  );
}
  
function getDescriptionParent() {
  return (
    <div>
        <p>
          We encourage you to sign your child up for an account – <b>and it's free!</b> 
        </p>

        <p>
          Once signing in, 
          your child will immediately get access to a wealth of interactive lessons, practices, and quizzes to build 
          programming skills and discover the joy of problem solving. Signup is necessary to enable a personalized learning experience
          since the system is based on advanced data driven technologies to target each individual student's needs. 
        </p>

        <p>
          What's special about CS Wonders?
        </p>

        <ul>
          <li> We advocate an approach combining <a href="learn">interactive online study</a> and <a href="classes">instructor-guided classes</a>.</li>
          <li> The online self-paced learning system, developed by top industry experts, is personalized and interactive, providing real-time feedback to make sure your child masters each topic.</li>
          <li> In offline classes, instructors provide guidance and encouragement to facilitate team work using the online tools. </li>
          <li> Your student will learn Python, the emerging star in the world of programming languages.</li>
          <li> Your student will learn proven computer science concepts.</li>
          <li> Your student will be able to build real solutions from scratch and get better at problem solving.</li>
        </ul>

    </div>
  );
}
  
function getDescriptionTeacher() {
  return (
    <div>

        <p>
          We provide powerful tools exclusively for teachers to make CS teaching effective and fun. 
        </p>

        <ul>
          <li> Leverage existing high quality, interactive courseware out of the box.</li>
          <li> Create your own projects, lessons, exercises, and quizzes using CS Wonders online tools.</li>
          <li> Create a classroom to organize your students in minutes.</li>
          <li> Apply autograding to save time.</li>
          <li> Gain insights with real-time progress tracking.</li>
        </ul>

        <p>
          We welcome you to become a partner by contacting us at <b>cswonders.info@gmail.com</b>.
        </p>
        <p>
          Together, let’s make this world a little better, one child at a time!
        </p>
    </div>
  );
}
  
export default function Home(props) {
  useStyles(s);
  
  var user = props.store.getState().auth.user;

  if (Object.keys(user).length !== 0 && user.username) {
    if (isParent(user)) {
      return (<ParentProfilePage store={ props.store } />);
    }

    return (
      <div style={containerStyle}>
        <UserSwitcher target='LEARN' store={ props.store } />
      </div>
    );
  }

  return (
    <div className={s.root}>
      <section style={splashSection}>

        <header style={sloganHeader}>
          <h4 style={slogan}>Learn. Practice. Create.</h4>
          <h6 style={subSlogan}>Personalized Computer Science Learning for Children Ages 9-18</h6>
          {
          // <a href="../signup" className={s.courseButton}>Create Free Account</a>
          }
        </header>
      </section>

      <div style={descriptionContainerStyle}>
      
        <div style={descriptionTitle}>
          Discover What Is Possible with CS Wonders
        </div>

        <br />

        <div className="row">
          <div className="col-sm-2"> </div>
          <div className="col-sm-8">
            <div style={descriptionStyle}>
              {
                getDescription()
              }
            </div>
          <div className="col-sm-2"> </div>
        </div>

      </div>

      </div>

      <div style={descriptionContainerStyle}>

        <div style={descriptionTitle}>
          If You Are a Parent
        </div>

        <br />

        <div className="row">
          <div className="col-sm-2"> </div>
          <div className="col-sm-8">
            <div style={descriptionStyle}>
              {
                getDescriptionParent()
              }
            </div>
          <div className="col-sm-2"> </div>
        </div>

      </div>

      </div>

      <div style={descriptionContainerStyle}>

        <div style={descriptionTitle}>
          If You Are a Teacher
        </div>

        <br />

        <div className="row">
          <div className="col-sm-2"> </div>
          <div className="col-sm-8">
            <div style={descriptionStyle}>
              {
                getDescriptionTeacher()
              }
            </div>
          <div className="col-sm-2"> </div>
        </div>

      </div>

        <br />
      </div>

      <div style={descriptionContainerStyle}>

        <div style={descriptionTitle}>
          Personalized Learning System
        </div>

        <br />

        <div className="row">
          <div className="col-sm-2"> </div>
          <div className="col-sm-8">
            <div style={{'padding':'20px', 'borderRadius':'20px', 'backgroundColor': 'white'}}>
              <img src='img/personalized.png' style={imgStyle}></img>
            </div>
          <div className="col-sm-2"> </div>
        </div>

      </div>

        <br />
      </div>

      <div style={descriptionContainerStyle}>

        <div style={descriptionTitle}>
          Data Driven and Autograding
        </div>

        <br />

        <div className="row">
          <div className="col-sm-2"> </div>
          <div className="col-sm-8">
            <div style={{'padding':'20px', 'borderRadius':'20px', 'backgroundColor': 'white'}}>
              <img src='img/datadriven.png' style={imgStyle}></img>
            </div>
          <div className="col-sm-2"> </div>
        </div>

      </div>

        <br />
      </div>

      <div style={descriptionContainerStyle}>

        <div style={descriptionTitle}>
          Interactive and Focused On Problem Solving
        </div>

        <br />

        <div className="row">
          <div className="col-sm-2"> </div>
          <div className="col-sm-8">
            <div style={{'padding':'20px', 'borderRadius':'20px', 'backgroundColor': 'white'}}>
              <img src='img/interactive.png' style={imgStyle}></img>
            </div>
          <div className="col-sm-2"> </div>
        </div>

      </div>

        <br />
      </div>

{
      // <div style={introTitle}>
      //   Online Tutorials
      // </div>

      // <div style={intro}>
      //   <p>You can use tutorials without signing in</p>
      //   <p>To access additional CS Wonders courseware, please sign in</p>
      // </div>

      // {
      //   <Catalog catalog={curriculum} />
      // }
}
    </div>
  );
}
