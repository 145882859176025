/*
 * routes/home/index.js
 */
import React from 'react';
import Home from './Home';
import Layout from '../../components/Layout';

export default function action({ store }) {
  return {
    title: 'CS Wonders',
    chunks: ['home'],
    component: (
      <Layout store={store}>
        <Home store={store}/>
      </Layout>
    ),
  };
};
